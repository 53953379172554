<template>
  <NuxtLink
    target="_blank"
    to="https://formkit.com"
    class="fk-credit bg-gray-900/80 flex items-center rounded-lg py-2 pl-2 pr-2.5 ring-2 ring-gray-100/60 hover:ring-2 hover:ring-pink-500 hover:bg-gray-950 cursor-pointer"
  >
    <Logo :mark-only="true" class="!w-5 mr-2" />
    <div class="text-white flex flex-col">
      <span class="text-[10px] leading-none">Make free forms with</span>
      <span class="text-[17px] font-semibold leading-none tracking-wide"
        >FormKit.com</span
      >
    </div>
  </NuxtLink>
</template>
