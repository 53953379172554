export const layoutClasses = {
  base: {
    outer: "group bg-fk-temp-page-bg relative flex w-full",
    logo: "fixed flex items-center",
    credit: "fixed flex items-center",
    wrapper: "mx-auto w-full flex flex-1",
    content: "relative flex z-10",
    blurUnderlay: "pointer-events-none z-0 transform-gpu",
    contentInner:
      "w-full flex px-8 @2xl:px-12 @5xl:px-16 pt-14 pb-10 overflow-visible",
    form: "w-full m-auto @3xl:grid @3xl:gap-x-5 @3xl:grid-cols-2",
    image: "fixed overflow-clip",
  },
  minimal: {
    outer: "outer",
    logo: "z-30 top-[2em] right-[2em] max-w-[140px] justify-end",
    credit: `
      z-30
      bottom-[1.5em]
      right-[2em]
      justify-end
    `,
    wrapper: "max-w-[100%] p-0",
    content: "top-0 left-0 w-full",
    blurUnderlay: "fixed inset-0",
    contentInner: "bg-white min-h-full h-[min-content]",
    image: "top-[10%] left-[10%] w-[70%] h-[70%] rounded-[10%] opacity-0",
  },
  card: {
    outer: "outer",
    logo: "z-30 top-[2em] right-[2em] max-w-[140px] justify-end",
    credit: "z-30 bottom-[1.5em] right-[2em] justify-end",
    wrapper: "py-24 2@7xl:py-32 my-auto !min-w-none",
    content: "top-0 left-0 p-2 w-[100%] max-h-none shrink-0",
    contentInner: `
      bg-white
      ring-1
      ring-gray-900/10
      rounded-lg
      h-[min-content]
      group-data-[bg-shadow=true]:shadow-[0_0.25em_1em_-0.25em_rgba(0,0,0,0.15)]
      group-data-[bg-shadow=false]:shadow-none
    `,
    blurUnderlay: "absolute inset-2 rounded-lg",
    image: "top-0 left-0 min-w-full min-h-full w-full h-full rounded-none",
  },
  "left-image": {
    outer: "outer",
    logo: "z-30 top-[2em] left-[2em] max-w-[140px] justify-start",
    credit: "z-30 bottom-[1.5em] left-[2em] justify-end",
    wrapper: "max-w-[100%] p-0",
    content: `
      top-0 
      min-h-full
      left-0
      @xl:left-[15%]
      @3xl:left-[30%]
      @5xl:left-[40%]
      w-full
      @xl:w-[85%]
      @3xl:w-[70%]
      @5xl:w-[60%]
      @3xl:group-data-[has-scrollbars=true]:left-[calc(30%+(var(--scrollbar-width))-1px)] 
      @3xl:group-data-[has-scrollbars=true]:w-[calc(70%-(var(--scrollbar-width))+1px)]
      @5xl:group-data-[has-scrollbars=true]:left-[calc(40%+(var(--scrollbar-width))-1px)] 
      @5xl:group-data-[has-scrollbars=true]:w-[calc(60%-(var(--scrollbar-width))+1px)]
    `,
    blurUnderlay: `
        fixed
        top-0
        bottom-0
        left-0
        @xl:left-[15%]
        @3xl:left-[30%]
        @5xl:left-[40%]
        w-full
        @xl:w-[85%]
        @3xl:w-[70%]
        @5xl:w-[60%]
        @3xl:group-data-[has-scrollbars=true]:left-[calc(30%+(var(--scrollbar-width))-1px)]
        @3xl:group-data-[has-scrollbars=true]:w-[calc(70%-(var(--scrollbar-width))+1px)]
        @5xl:group-data-[has-scrollbars=true]:left-[calc(40%+(var(--scrollbar-width))-1px)]
        @5xl:group-data-[has-scrollbars=true]:w-[calc(60%-(var(--scrollbar-width))+1px)]
      `,
    contentInner:
      "bg-white min-h-full h-[min-content] group-data-[bg-shadow=true]:!shadow-[-0.5em_0em_0.5em_-0.4em_rgba(0,0,0,0.1)] group-data-[bg-shadow=false]:!shadow-none",
    image:
      "top-0 left-0 w-0 @xl:w-[15%] @3xl:w-[30%] @5xl:w-[40%] h-full rounded-none",
  },
  "right-image": {
    outer: "outer",
    logo: "z-30 top-[2em] right-[2em] max-w-[140px] justify-end",
    credit: "z-30 bottom-[1.5em] right-[2em] justify-end",
    wrapper: "max-w-[100%] p-0",
    content: `
      top-0
      left-0
      min-h-full
      w-full
      @xl:w-[85%]
      @3xl:w-[70%]
      @5xl:w-[60%]
      @xl:group-data-[has-scrollbars=true]:w-[calc(85%+(var(--scrollbar-width))+1px]
      @3xl:group-data-[has-scrollbars=true]:w-[calc(70%+(var(--scrollbar-width))+1px)]
      @5xl:group-data-[has-scrollbars=true]:w-[calc(60%+(var(--scrollbar-width))+1px)]
    `,
    blurUnderlay: `
        fixed
        top-0
        bottom-0
        left-0
        w-full
        @xl:w-[85%]
        @3xl:w-[70%]
        @5xl:w-[60%]
        @xl:group-data-[has-scrollbars=true]:w-[calc(85%+(var(--scrollbar-width))+1px)]
        @3xl:group-data-[has-scrollbars=true]:w-[calc(70%+(var(--scrollbar-width))+1px)]
        @5xl:group-data-[has-scrollbars=true]:w-[calc(60%+(var(--scrollbar-width))+1px)]
      `,
    contentInner:
      "bg-white min-h-full h-[min-content] group-data-[bg-shadow=true]:!shadow-[0.5em_0em_0.5em_-0.4em_rgba(0,0,0,0.1)] group-data-[bg-shadow=false]:!shadow-none",
    image: `
        top-0 
        left-0
        @xl:left-[85%]
        @3xl:left-[70%]
        @5xl:left-[60%]
        w-0
        @xl:w-[15%]
        @3xl:w-[30%]
        @5xl:w-[40%]
        @xl:group-data-[has-scrollbars=true]:left-[calc(85%-(var(--scrollbar-width))-1px)]
        @3xl:group-data-[has-scrollbars=true]:left-[calc(70%-(var(--scrollbar-width))-1px)]
        @5xl:group-data-[has-scrollbars=true]:left-[calc(60%-(var(--scrollbar-width))-1px)]
        @xl:group-data-[has-scrollbars=true]:w-[calc(15%+(var(--scrollbar-width))+1px)]
        @3xl:group-data-[has-scrollbars=true]:w-[calc(30%+(var(--scrollbar-width))+1px)]
        @5xl:group-data-[has-scrollbars=true]:w-[calc(40%+(var(--scrollbar-width))+1px)]
        h-full
        rounded-none
      `,
  },
  "top-image": {
    outer: "flex-col",
    logo: "z-20 top-[2em] right-[2em] max-w-[140px] justify-end",
    credit: "z-30 bottom-[1.5em] right-[2em] justify-end",
    wrapper: "max-w-[100%] p-0 flex",
    content: `
      top-0
      left-0
      w-full
      h-full
      z-10
      pt-28
      @3xl:pt-32
      @5xl:pt-48
      @7xl:pt-60
      mb-0
      !bg-transparent
      !overflow-visible
    `,
    blurUnderlay: `
      absolute
      top-28
      @3xl:top-32
      @5xl:top-48
      @7xl:top-60
      left-0
      right-0
      bottom-0
    `,
    contentInner: `
      bg-white
      w-full
      min-h-full
      h-[min-content]
      group-data-[bg-shadow=false]:!shadow-none
      group-data-[bg-shadow=true]:!shadow-[0_-0.33em_0.4em_-0.25em_rgba(0,0,0,0.1)]
    `,
    image: `
      top-0
      left-0
      w-full
      h-28
      @3xl:h-32
      @5xl:h-48
      @7xl:h-60
      rounded-none
    `,
  },
};
