import type { FormKitNode } from "@formkit/core";

/**
 * @privateRemarks
 * This file was generated by the FormKit CLI and should not be manually
 * edited unless you’d like to "eject" from the CLI’s ability to update it.
 *
 * @checksum - 16aaa4238e45e197ccd129a445c8895e78c6bc122029905c5225fd72c9706cb8
 * @variables - undefined
 * @theme - varia
 **/

/**
 * This is the theme function itself, it should be imported and used as the
 * config.rootClasses function. For example:
 *
 * ```js
 * import { theme } from './formkit.theme'
 * import { defineFormKitConfig } from '@formkit/vue'
 *
 * export default defineFormKitConfig({
 *   config: {
 *     rootClasses: theme
 *   }
 * })
 * ```
 **/
export function rootClasses(
  sectionName: string,
  node: FormKitNode
): Record<string, boolean> {
  const key = `${node.props.type}__${sectionName}`;
  const semanticKey = `formkit-${sectionName}`;
  const familyKey = node.props.family
    ? `family:${node.props.family}__${sectionName}`
    : "";
  const memoKey = `${key}__${familyKey}`;
  if (!(memoKey in classes)) {
    const sectionClasses = classes[key] ?? globals[sectionName] ?? {};
    sectionClasses[semanticKey] = true;
    if (familyKey in classes) {
      classes[memoKey] = { ...classes[familyKey], ...sectionClasses };
    } else {
      classes[memoKey] = sectionClasses;
    }
  }
  return classes[memoKey] ?? { [semanticKey]: true };
}

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes: Record<string, Record<string, boolean>> = {
  "family:button__wrapper": {
    "group-data-[disabled=true]:grayscale": true,
  },
  "family:button__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "font-bold": true,
    "rounded-fk": true,
    "outline-none": true,
    flex: true,
    "!fk-text-small": true,
    "px-fk-spacing-2xl": true,
    "py-fk-spacing-xl": true,
    "items-center": true,
    "mb-fk-spacing-sm": true,
    "fk-text-small": true,
    "ring-offset-2": true,
    "ring-fk-accent": true,
    "focus-visible:ring-2": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "shadow-sm": true,
    "group-data-[prefix-icon]:pl-fk-spacing-3xl": true,
    "group-data-[suffix-icon]:pr-fk-spacing-3xl": true,
    border: true,
    "border-fk-accent": true,
    "text-fk-accent": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  "family:button__prefixIcon": {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!-ml-fk-spacing-xl": true,
  },
  "family:button__suffixIcon": {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!-mr-fk-spacing-xl": true,
  },
  "family:box__wrapper": {
    "inline-flex": true,
    "items-center": true,
    "mb-fk-spacing-sm": true,
    "group-data-[multiple]:mb-0": true,
  },
  "family:box__legend": {
    "fk-text-small": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
    "mb-fk-spacing": true,
  },
  "family:box__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    peer: true,
    "pointer-events-none": true,
    absolute: true,
    "h-0": true,
    "w-0": true,
    "overflow-hidden": true,
    "opacity-0": true,
  },
  "family:box__decorator": {
    "mr-fk-spacing-sm": true,
    "bg-white/40": true,
    "ring-fk-accent": true,
    "peer-checked:border-fk-accent": true,
    relative: true,
    block: true,
    "fk-text-large": true,
    "w-[1em]": true,
    "aspect-[1/1]": true,
    border: true,
    "border-fk-temp-lighter": true,
    "text-transparent": true,
    "peer-checked:bg-fk-accent-lightest": true,
    "peer-checked:text-fk-accent": true,
    "peer-focus-visible:ring-2": true,
    "peer-focus-visible:ring-offset-1": true,
    "select-none": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "peer-disabled:bg-fk-temp-lightest": true,
    "group-data-[disabled]:grayscale": true,
    "shadow-sm": true,
    "peer-disabled:cursor-not-allowed": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  "family:box__decoratorIcon": {
    absolute: true,
    "left-1/2": true,
    "top-1/2": true,
    flex: true,
    "h-full": true,
    "w-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
  },
  "family:box__option": {
    "mb-fk-spacing-sm": true,
    "last:mb-0": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "group-data-[disabled]:data-[disabled]:opacity-100": true,
  },
  "family:box__label": {
    "fk-text-small": true,
    "mb-fk-spacing-sm": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
    "!mb-0": true,
    "!font-normal": true,
    "!fk-text-smaller": true,
  },
  "family:box__optionHelp": {
    "text-fk-temp-light": true,
    "fk-text-smaller": true,
    "-mt-1": true,
    "mb-fk-spacing-sm": true,
    "ml-fk-spacing-sm": true,
    relative: true,
    "left-[calc(1.25em+2px)]": true,
  },
  "family:box__help": {
    "fk-text-smaller": true,
    "text-fk-temp-light": true,
    "mb-fk-spacing-sm": true,
    "group-data-[multiple]:mb-fk-spacing": true,
    "group-data-[multiple]:-mt-1.5": true,
  },
  "family:text__wrapper": {
    flex: true,
    "flex-col": true,
    "items-start": true,
    "justify-start": true,
    "mb-fk-spacing-sm": true,
    "last:mb-0": true,
  },
  "family:text__label": {
    "fk-text-small": true,
    "mb-fk-spacing-sm": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
    "!inline-flex": true,
  },
  "family:text__inner": {
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "w-full": true,
    "py-fk-spacing": true,
    "px-fk-spacing-lg": true,
    "rounded-fk": true,
    border: true,
    "border-fk-temp-lighter": true,
    "bg-white/40": true,
    "focus-within:ring-1": true,
    "focus-within:!ring-fk-accent": true,
    "focus-within:!border-fk-accent": true,
    "group-data-[disabled]:bg-fk-temp-lightest": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "shadow-sm": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
    "group-data-[input-style=underline]/container:rounded-none": true,
    "group-data-[input-style=underline]/container:!ring-transparent": true,
    "group-data-[input-style=underline]/container:!border-transparent": true,
    "group-data-[input-style=underline]/container:border-b-2": true,
    "group-data-[input-style=underline]/container:!border-b-fk-temp-form-bg-dark":
      true,
    "group-data-[input-style=underline]/container:focus-within:!border-b-fk-accent":
      true,
    "group-data-[input-style=underline]/container:bg-fk-temp-form-bg": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  "family:text__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "fk-text-base": true,
    "text-fk-temp": true,
    "min-w-0": true,
    "min-h-[1.5em]": true,
    grow: true,
    "outline-none": true,
    "bg-transparent": true,
    "selection:bg-fk-accent-lightest": true,
    "placeholder:text-fk-temp-light": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "border-none": true,
    "p-0": true,
    "focus:ring-0": true,
  },
  "family:text__prefixIcon": {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-temp": true,
  },
  "family:text__suffixIcon": {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-temp": true,
  },
  "family:dropdown__wrapper": {
    "mb-fk-spacing-sm": true,
  },
  "family:dropdown__inner": {
    relative: true,
    flex: true,
    "items-center": true,
    "bg-white/40": true,
    border: true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    "group-data-[is-multiline]:!radius-none": true,
    "focus-within:ring-1": true,
    "focus-within:!ring-fk-accent": true,
    "focus-within:!border-fk-accent": true,
    "group-data-[disabled]:bg-fk-temp-lightest": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "shadow-sm": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
    "group-data-[input-style=pill]/container:group-data-[is-multiline]:rounded-2xl":
      true,
    "group-data-[input-style=underline]/container:rounded-none": true,
    "group-data-[input-style=underline]/container:!ring-transparent": true,
    "group-data-[input-style=underline]/container:!border-transparent": true,
    "group-data-[input-style=underline]/container:border-b-2": true,
    "group-data-[input-style=underline]/container:!border-b-fk-temp-form-bg-dark":
      true,
    "group-data-[input-style=underline]/container:focus-within:!border-b-fk-accent":
      true,
    "group-data-[input-style=underline]/container:bg-fk-temp-form-bg": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  "family:dropdown__input": {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    grow: true,
    "p-fk-spacing": true,
    "pr-0": true,
    "pl-fk-spacing-lg": true,
    "fk-text-base": true,
    "text-fk-temp": true,
    "text-ellipsis": true,
    "min-w-0": true,
    "outline-none": true,
    "bg-transparent": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "placeholder:text-fk-temp-light": true,
    "selection:bg-fk-accent-lightest": true,
    "border-none": true,
    "focus:ring-0": true,
    "bg-none": true,
  },
  "family:dropdown__listboxButton": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "text-fk-temp": true,
    "z-10": true,
    "data-[disabled]:cursor-not-allowed": true,
  },
  "family:dropdown__removeSelection": {
    "w-[2.5em]": true,
    "self-stretch": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "text-fk-temp": true,
    "hover:text-red-400": true,
    "z-10": true,
  },
  "family:dropdown__controlLabel": {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true,
  },
  "family:dropdown__selectIcon": {
    "fk-text-base": true,
    "inline-flex": true,
    "justify-center": true,
    "w-[2.5em]": true,
    relative: true,
    "my-auto": true,
    "[&>svg]:w-[1em]": true,
    "[&>svg]:mx-auto": true,
  },
  "family:dropdown__closeIcon": {
    "fk-text-base": true,
    "w-[0.75em]": true,
    relative: true,
    "m-auto": true,
  },
  "family:dropdown__prefixIcon": {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!ml-fk-spacing": true,
    "!mr-0": true,
    "text-fk-temp": true,
  },
  "family:dropdown__suffixIcon": {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-fk-spacing": true,
    "!ml-0": true,
    "text-fk-temp": true,
  },
  "family:dropdown__dropdownWrapper": {
    "rounded-fk-sm": true,
    "shadow-lg": true,
    "mt-px": true,
    "overflow-clip": true,
    "empty:hidden": true,
    border: true,
    "border-fk-temp-lighter": true,
    "group-data-[expanded]:opacity-100": true,
    "group-data-[overscroll]:m-0": true,
    "group-data-[overscroll]:shadow-none": true,
    "group-data-[overscroll]:border-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-3xl": true,
    "group-data-[input-style=pill]/container:mt-1": true,
    "group-data-[input-style=underline]/container:mt-1": true,
  },
  "family:dropdown__listitemGroup": {
    "group/optgroup": true,
    "last:pb-0": true,
    "border-t": true,
    "border-b": true,
    "-mb-px": true,
    "border-fk-temp-lighter": true,
  },
  "family:dropdown__groupLabel": {
    block: true,
    "pt-fk-spacing-sm": true,
    "pb-fk-spacing-sm": true,
    "px-fk-spacing-lg": true,
    "font-bold": true,
    "pointer-events-none": true,
    "text-fk-temp-dark": true,
  },
  "family:dropdown__listbox": {
    "bg-white": true,
    "rounded-fk-sm": true,
    "empty:hidden": true,
    "overflow-clip": true,
    "group-data-[overscroll]:shadow-lg": true,
    "group-data-[overscroll]:border": true,
    "group-data-[overscroll]:border-fk-temp-lighter": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-3xl": true,
  },
  "family:dropdown__listitem": {
    relative: true,
    flex: true,
    "items-center": true,
    "px-fk-spacing": true,
    "py-fk-spacing-sm": true,
    "first:pt-fk-spacing": true,
    "last:pb-fk-spacing": true,
    "text-fk-temp": true,
    "fk-text-base": true,
    "data-[is-active]:bg-fk-accent-lightest": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "data-[is-active]:first:before:rounded-fk-sm": true,
    "data-[is-active]:first:before:rounded-b-none": true,
    "data-[is-active]:last:before:rounded-fk-sm": true,
    "data-[is-active]:last:before:rounded-t-none": true,
    "data-[is-active]:first:last:before:rounded-fk-sm": true,
    "data-[is-active]:before:ring-1": true,
    "data-[is-active]:before:ring-fk-accent": true,
    "data-[is-active]:before:ring-inset": true,
    "data-[is-active]:before:ring-offset-fk-accent-lightest": true,
    "group-[]/optgroup:first:before:!rounded-none": true,
    "group-[]/optgroup:last:before:!rounded-none": true,
    "group-data-[input-style=square]/container:before:!rounded-none": true,
    "group-data-[input-style=pill]/container:data-[is-active]:first:before:!rounded-t-3xl":
      true,
    "group-data-[input-style=pill]/container:data-[is-active]:last:before:!rounded-b-3xl":
      true,
  },
  "family:dropdown__selectedIcon": {
    flex: true,
    absolute: true,
    "items-center": true,
    "text-fk-accent": true,
    "left-fk-spacing": true,
    "fk-text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  "family:dropdown__option": {
    "ml-[1.5em]": true,
  },
  "family:dropdown__loadMore": {
    "data-[is-active]:bg-fk-accent-lightest": true,
  },
  "family:dropdown__loadMoreInner": {
    "fk-text-small": true,
    "p-fk-spacing": true,
    "[&>span]:mr-fk-spacing": true,
    flex: true,
    "text-fk-temp-light": true,
    "items-center": true,
    "justify-center": true,
    "cursor-pointer": true,
  },
  "family:dropdown__selectionWrapper": {
    grow: true,
    flex: true,
    "items-center": true,
    "text-fk-temp": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
    "group-data-[input-style=underline]/container:!rounded-none": true,
  },
  "family:dropdown__selection": {
    grow: true,
    "text-fk-temp": true,
    "group-data-[multiple]:p-fk-spacing": true,
  },
  "family:dropdown__tagsWrapper": {
    "w-full": true,
  },
  "family:dropdown__tagWrapper": {
    "group/tag": true,
    flex: true,
    "items-center": true,
    rounded: true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "data-[active-selection=true]:ring-fk-accent": true,
  },
  "family:dropdown__tags": {
    flex: true,
    "flex-wrap": true,
    "gap-1": true,
    "items-center": true,
    "w-full": true,
    "empty:mb-0": true,
  },
  "family:dropdown__tag": {
    flex: true,
    "items-center": true,
    "cursor-default": true,
    rounded: true,
    "fk-text-smaller": true,
    "px-fk-spacing-sm": true,
    "py-px": true,
    "bg-fk-accent": true,
    "text-white": true,
    "[&>[type=button]]:!w-[0.75em]": true,
    "[&>[type=button]]:aspect-[1/1]": true,
    "[&>[type=button]]:!text-inherit": true,
    "[&>[type=button]]:cursor-pointer": true,
    "[&>[type=button]_span]:flex-shrink-0": true,
    "[&>[type=button]_span]:!w-[0.75em]": true,
    "group-data-[active-selection=true]/tag:bg-fk-accent-lightest": true,
    "group-data-[active-selection=true]/tag:text-fk-temp": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-xl": true,
  },
  "family:dropdown__tagLabel": {
    "mr-1": true,
    "leading-normal": true,
    "py-px": true,
  },
  "family:dropdown__emptyMessage": {
    flex: true,
    "items-center": true,
    "px-fk-spacing": true,
    "py-fk-spacing-sm": true,
    "first:pt-fk-spacing": true,
    "last:pb-fk-spacing": true,
    "text-fk-temp": true,
    "fk-text-small": true,
    "aria-selected:text-white": true,
    "aria-selected:bg-fk-accent": true,
  },
  button__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "bg-fk-accent-lightest": true,
    "hover:bg-fk-accent-lightest": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  checkbox__decorator: {
    rounded: true,
    "group-data-[input-style=square]/container:!rounded-none": true,
  },
  checkbox__decoratorIcon: {
    "max-w-[66.66%]": true,
  },
  file__fileList: {
    "group/list": true,
    peer: true,
    "w-full": true,
    "min-w-0": true,
    "data-[has-multiple]:mb-[1.25em]": true,
  },
  file__fileItemIcon: {
    "h-[1em]": true,
    "w-[1em]": true,
    "mr-fk-spacing": true,
    "shrink-0": true,
  },
  file__fileItem: {
    flex: true,
    "min-w-0": true,
    "items-center": true,
    "text-fk-temp": true,
    "mb-fk-spacing-sm": true,
    "last:mb-0": true,
  },
  file__fileName: {
    truncate: true,
    "min-w-0": true,
    "w-full": true,
    shrink: true,
    "leading-5": true,
    "group-data-[has-multiple]/list:fk-text-small": true,
  },
  file__fileRemove: {
    "right-fk-spacing": true,
    "ring-fk-accent": true,
    "rounded-fk": true,
    "z-20": true,
    flex: true,
    "appearance-none": true,
    "items-center": true,
    "text-[0px]": true,
    "outline-none": true,
    "hover:!text-red-500": true,
    "focus-visible:ring-2": true,
    "group-data-[disabled]:pointer-events-none": true,
    "group-data-[disabled]:!text-fk-temp-light": true,
    "peer-data-[has-multiple]:absolute": true,
    "peer-data-[has-multiple]:bottom-[max(0.5em,8px)]": true,
    "peer-data-[has-multiple]:left-fk-spacing-lg": true,
    "peer-data-[has-multiple]:text-fk-accent": true,
    "peer-data-[has-multiple]:fk-text-smaller": true,
    "peer-data-[has-multiple]:whitespace-nowrap": true,
    "group-data-[prefix-icon]:peer-data-[has-multiple]:left-fk-spacing": true,
    "group-data-[input-style=square]/container:rounded-none": true,
  },
  file__fileRemoveIcon: {
    block: true,
    "fk-text-base": true,
    "w-[0.75em]": true,
    relative: true,
    "z-10": true,
  },
  file__inner: {
    relative: true,
    "cursor-pointer": true,
    "group-data-[has-multiple]:rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:group-data-[has-multiple]:rounded-2xl":
      true,
  },
  file__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "cursor-pointer": true,
    "text-transparent": true,
    absolute: true,
    "inset-0": true,
    "opacity-0": true,
    "z-10": true,
    "file:pointer-events-none": true,
    "file:w-0": true,
    "file:h-0": true,
    "file:overflow-hidden": true,
  },
  file__noFiles: {
    flex: true,
    "w-full": true,
    "items-center": true,
    "text-fk-temp-light": true,
  },
  file__noFilesIcon: {
    "w-[1em]": true,
    "mr-fk-spacing": true,
  },
  form__form: {
    "group/form": true,
  },
  form__summaryInner: {
    "group/summary": true,
    border: true,
    "border-fk-temp-lighter": true,
    "bg-white": true,
    "rounded-fk-sm": true,
    "py-fk-spacing": true,
    "px-fk-spacing-lg": true,
    "shadow-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
  },
  form__summaryHeader: {
    "fk-text-large": true,
    "text-fk-temp": true,
    "font-bold": true,
    "mb-fk-spacing": true,
  },
  form__message: {
    "px-fk-spacing": true,
    "py-fk-spacing-xs": true,
    "my-fk-spacing-xs": true,
    "mr-fk-spacing-xs": true,
    "fk-text-smaller": true,
    "inline-flex": true,
    "bg-red-600": true,
    "text-red-50": true,
    "rounded-lg": true,
    "leading-tight": true,
    "group-[]/summary:fk-text-small": true,
  },
  form__messageLink: {
    "group-[]/summary:outline-none": true,
    "group-[]/summary:focus-visible:ring-2": true,
    "group-[]/summary:ring-fk-accent": true,
  },
  radio__decorator: {
    "rounded-full": true,
  },
  radio__decoratorIcon: {
    "max-w-[50%]": true,
  },
  submit__outer: {
    group: true,
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "max-w-none": true,
    "min-w-0": true,
    grow: true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-100": true,
  },
  submit__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "bg-fk-accent": true,
    "!text-white": true,
    "active:text-fk-accent-lightest": true,
    "active:bg-fk-accent-light": true,
    "hover:bg-fk-accent-light": true,
    "disabled:border-fk-temp-lighter": true,
    "disabled:bg-fk-temp-lighter": true,
    "disabled:text-fk-temp-lighter": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
    "before:transition-all": true,
    "group-data-[loading=true]/form:before:content['']": true,
    "group-data-[loading=true]/form:before:block": true,
    "group-data-[loading=true]/form:before:animate-spin": true,
    "group-data-[loading=true]/form:before:w-5": true,
    "group-data-[loading=true]/form:before:h-5": true,
    "group-data-[loading=true]/form:before:rounded-full": true,
    "group-data-[loading=true]/form:before:mr-fk-spacing-lg": true,
    "group-data-[loading=true]/form:before:-ml-fk-spacing-sm": true,
    "group-data-[loading=true]/form:before:border-2": true,
    "group-data-[loading=true]/form:before:border-solid": true,
    "group-data-[loading=true]/form:before:border-white": true,
    "group-data-[loading=true]/form:before:border-r-transparent": true,
  },
  submit__prefixIcon: {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-accent-lighter": true,
  },
  submit__suffixIcon: {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-accent-lighter": true,
  },
  textarea__inner: {
    flex: true,
    "items-center": true,
    "mb-fk-spacing-sm": true,
    "bg-white/40": true,
    border: true,
    "border-fk-temp-lighter": true,
    "rounded-fk-sm": true,
    "focus-within:ring-1": true,
    "focus-within:!ring-fk-accent": true,
    "focus-within:!border-fk-accent": true,
    "group-data-[disabled]:bg-fk-temp-lightest": true,
    "shadow-sm": true,
    "group-[]/repeater:shadow-none": true,
    "group-[]/multistep:shadow-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
    "group-data-[input-style=underline]/container:rounded-none": true,
    "group-data-[input-style=underline]/container:!ring-transparent": true,
    "group-data-[input-style=underline]/container:!border-transparent": true,
    "group-data-[input-style=underline]/container:border-b-2": true,
    "group-data-[input-style=underline]/container:!border-b-fk-temp-form-bg-dark":
      true,
    "group-data-[input-style=underline]/container:focus-within:!border-b-fk-accent":
      true,
    "group-data-[input-style=underline]/container:bg-fk-temp-form-bg": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  textarea__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "fk-text-base": true,
    "h-32": true,
    "text-fk-temp": true,
    "min-w-0": true,
    grow: true,
    shrink: true,
    "!py-fk-spacing": true,
    "!px-fk-spacing-lg": true,
    "outline-none": true,
    "bg-transparent": true,
    "selection:bg-fk-accent-lightest": true,
    "placeholder:text-fk-temp-light": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "p-0": true,
    "border-none": true,
    "focus:ring-0": true,
  },
  textarea__prefixIcon: {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!ml-fk-spacing": true,
    "!mr-0": true,
    "text-fk-temp": true,
  },
  textarea__suffixIcon: {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "!mr-fk-spacing": true,
    "!ml-0": true,
    "text-fk-temp": true,
  },
  autocomplete__selections: {
    flex: true,
    absolute: true,
    "inset-0": true,
    "group-data-[multiple]:static": true,
    "group-data-[multiple]:block": true,
    "group-data-[empty]:hidden": true,
    "group-data-[multiple]:mt-fk-spacing-sm": true,
  },
  autocomplete__selectionWrapper: {
    "bg-fk-temp-lightest": true,
    "rounded-fk": true,
    "group-data-[multiple]:border": true,
    "group-data-[multiple]:border-fk-temp-lighter": true,
    "group-data-[multiple]:mb-fk-spacing-sm": true,
    "outline-none": true,
    "data-[active-selection=true]:ring-2": true,
    "data-[active-selection=true]:ring-fk-accent": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-3xl": true,
    "group-data-[input-style=underline]/container:border-none": true,
    "[&.formkit-dropZone]:opacity-25": true,
    "[&.formkit-touchDropZone]:opacity-25": true,
    "[&.formkit-touchDragging]:!flex": true,
    "[&.formkit-longTouch]:opacity-25": true,
  },
  autocomplete__selection: {
    "rounded-fk": true,
    just: true,
    "pl-fk-spacing": true,
    "[&>*]:ml-0": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-3xl": true,
  },
  colorpicker__outer: {
    group: true,
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "max-w-none": true,
    "min-w-0": true,
    grow: true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:cursor-not-allowed": true,
    "data-[disabled]:pointer-events-none": true,
  },
  colorpicker__help: {
    "fk-text-smaller": true,
    "text-fk-temp-light": true,
    "mt-1": true,
    "group-data-[inline]:-mt-1": true,
    "group-data-[inline]:mb-fk-spacing": true,
  },
  colorpicker__inner: {
    relative: true,
    "inline-flex": true,
    "!w-auto": true,
    "pl-fk-spacing": true,
    "group-data-[inline]:border-none": true,
    "group-data-[inline]:shadow-none": true,
    "group-data-[inline]:p-0": true,
    "group-data-[inline]:bg-transparent": true,
    "group-data-[inline]:outline-none": true,
    "group-data-[inline]:!ring-0": true,
    "group-data-[inline]:!w-full": true,
    "group-data-[inline]:rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__swatchPreview: {
    "w-full": true,
    flex: true,
    "justify-start": true,
    "items-center": true,
    "rounded-fk": true,
    "text-sm": true,
    "cursor-pointer": true,
    "outline-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__canvasSwatchPreviewWrapper: {
    relative: true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:rounded-fk": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:z-[2]": true,
    "group-data-[input-style=square]/container:before:rounded-none": true,
    "group-data-[input-style=pill]/container:before:rounded-full": true,
  },
  colorpicker__canvasSwatchPreview: {
    "fk-text-base": true,
    "rounded-fk": true,
    "aspect-[1/1]": true,
    "shrink-0": true,
    grow: true,
    "!w-[1.5em]": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__valueString: {
    "fk-text-base": true,
    "text-fk-temp": true,
    "selection:bg-fk-accent-lightest": true,
    "font-mono": true,
    "inline-block": true,
    "ml-fk-spacing": true,
    "mr-fk-spacing-sm": true,
  },
  colorpicker__panel: {
    absolute: true,
    "left-0": true,
    "top-full": true,
    "z-50": true,
    flex: true,
    "w-[100vw]": true,
    "max-w-[18.5em]": true,
    "touch-manipulation": true,
    "flex-col": true,
    "rounded-fk-sm": true,
    border: true,
    "bg-white": true,
    "p-fk-spacing": true,
    "shadow-lg": true,
    "group-data-[inline]:static": true,
    "group-data-[inline]:max-w-none": true,
    "border-fk-temp-lighter": true,
    "group-data-[inline]:z-auto": true,
    "group-data-[inline]:bg-white/40": true,
    "group-data-[inline]:w-full": true,
    "group-data-[inline]:shadow-sm": true,
    "group-data-[inline]:group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[inline]:group-data-[disabled]:!pointer-events-none": true,
    "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)]:w-auto":
      true,
    "group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)_[id^=swatches]>div]:w-[1.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none":
      true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:group-data-[inline]:shadow-none":
      true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
    "group-data-[input-style=underline]/container:mt-1": true,
    "group-data-[input-style=underline]/container:group-data-[inline]:shadow-none":
      true,
  },
  colorpicker__panelClose: {
    flex: true,
    "justify-end": true,
    "items-center": true,
    "text-fk-temp": true,
    "mb-fk-spacing-sm": true,
    "-mt-1": true,
    "border-none": true,
    "bg-none": true,
    "border-b": true,
    "border-fk-temp-lighter": true,
    "w-[calc(100%+1rem)]": true,
    "-ml-2": true,
    "pt-0": true,
    "pr-fk-spacing": true,
    "pb-fk-spacing-sm": true,
    "pl-fk-spacing": true,
  },
  colorpicker__closeIcon: {
    "w-[2rem]": true,
    "aspect-[1/1]": true,
    "p-1": true,
    "rounded-fk": true,
    border: true,
    "[&>svg]:w-full": true,
    "[&>svg]:aspect-[1/1]": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__controlGroup: {
    grid: true,
    "[grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e']": true,
    "mb-fk-spacing": true,
  },
  colorpicker__LS: {
    "[grid-area:a]": true,
    relative: true,
    "mb-fk-spacing": true,
  },
  colorpicker__canvas: {
    block: true,
    "w-full": true,
  },
  colorpicker__canvasLS: {
    "aspect-[2/1]": true,
    "cursor-pointer": true,
    "rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-lg": true,
  },
  colorpicker__canvasHue: {
    "rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
  },
  colorpicker__canvasAlpha: {
    "rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
  },
  colorpicker__preview: {
    "rounded-fk": true,
    "after:rounded-fk": true,
    relative: true,
    "inline-flex": true,
    "aspect-[1/1]": true,
    "overflow-hidden": true,
    "[grid-area:b]": true,
    "mr-fk-spacing": true,
    "after:absolute": true,
    "after:left-0": true,
    "after:top-0": true,
    "after:h-full": true,
    "after:w-full": true,
    "after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "after:content-['']": true,
    "w-[2em]": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:after:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__hue: {
    "[grid-area:c]": true,
    relative: true,
    "inline-flex": true,
    "h-3/4": true,
  },
  colorpicker__alpha: {
    "[grid-area:d]": true,
    relative: true,
    "inline-flex": true,
    "h-3/4": true,
  },
  colorpicker__eyeDropper: {
    "[grid-area:e]": true,
    "w-[2em]": true,
    "ml-fk-spacing": true,
    "inline-flex": true,
    "self-center": true,
    "justify-center": true,
    "justify-self-center": true,
    "aspect-[1/1]": true,
    "rounded-fk-sm": true,
    border: true,
    "border-fk-temp-lighter": true,
    "cursor-pointer": true,
    "content-center": true,
    "items-center": true,
    "text-fk-temp": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__eyeDropperIcon: {
    "w-auto": true,
    "[&>svg]:w-[1em]": true,
  },
  colorpicker__control: {
    absolute: true,
    "bg-white": true,
    "shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
    "-translate-y-1/2": true,
    "-translate-x-1/2": true,
    "pointer-events-none": true,
    "data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]":
      true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-offset-2": true,
    "focus-visible:ring-fk-accent": true,
  },
  colorpicker__controlLS: {
    "w-[10px]": true,
    "h-[10px]": true,
    "rounded-full": true,
  },
  colorpicker__controlHue: {
    "w-[4px]": true,
    "h-[calc(100%-2px)]": true,
    "top-1/2": true,
    "rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__controlAlpha: {
    "w-[4px]": true,
    "h-[calc(100%-2px)]": true,
    "top-1/2": true,
    "rounded-fk-sm": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__formatField: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    grow: true,
  },
  colorpicker__colorField: {
    "bg-transparent": true,
    "text-fk-temp": true,
    border: true,
    "border-fk-temp-lighter": true,
  },
  colorpicker__colorInputGroup: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    grow: true,
  },
  colorpicker__fieldGroup: {
    flex: true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "w-full": true,
    "mr-fk-spacing-sm": true,
    "[&>input]:p-1": true,
    "[&>input]:fk-text-smaller": true,
    "[&>input]:text-fk-temp": true,
    "[&>input]:selection:bg-fk-accent-lightest": true,
    "[&>input]:m-0": true,
    "[&>input]:grow": true,
    "[&>input]:shrink": true,
    "[&>input]:w-full": true,
    "[&>input]:border": true,
    "[&>input]:border-fk-temp-lighter": true,
    "[&>input]:rounded-fk": true,
    "[&>input]:text-center": true,
    "[&>input]:appearance-none": true,
    "[&>input::-webkit-outer-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:m-0": true,
    "[&>input:focus]:outline-none": true,
    "[&>input:focus]:ring-1": true,
    "[&>input:focus]:ring-fk-accent": true,
    "[&>input:focus]:border-fk-accent": true,
    "max-[431px]:[&>input]:fk-text-base": true,
    "group-data-[input-style=square]/container:[&>input]:rounded-none": true,
    "group-data-[input-style=pill]/container:[&>input]:rounded-full": true,
  },
  colorpicker__fieldLabel: {
    "fk-text-smallest": true,
    "text-fk-temp-light": true,
    "mt-fk-spacing-xs": true,
  },
  colorpicker__formatSwitcher: {
    flex: true,
    "justify-end": true,
    "self-start": true,
    uppercase: true,
    "shrink-0": true,
    "p-1": true,
    "mt-0.5": true,
    "text-fk-temp": true,
    "rounded-fk-sm": true,
    "select-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  colorpicker__switchIcon: {
    "[&>svg]:w-3": true,
  },
  colorpicker__swatches: {
    "inline-flex": true,
    "flex-wrap": true,
    "w-full": true,
    "justify-self-center": true,
    "min-w-0": true,
    "mx-auto": true,
    "px-[1px]": true,
    "pt-fk-spacing": true,
    "pb-fk-spacing": true,
    "mt-fk-spacing": true,
    "-mb-fk-spacing": true,
    "border-t": true,
    "border-fk-temp-lighter": true,
    "overflow-auto": true,
    "max-h-[200px]": true,
    "select-none": true,
    "first:-mt-[3px]": true,
    "first:last:-mb-[3px]": true,
    "first:last:pb-[2px]": true,
    "first:pt-px": true,
    "first:border-t-0": true,
  },
  colorpicker__swatchGroup: {
    flex: true,
    "flex-wrap": true,
    "w-full": true,
    "mb-fk-spacing": true,
    "last:mb-0": true,
  },
  colorpicker__swatchGroupLabel: {
    "ml-1": true,
    block: true,
    "w-full": true,
    "fk-text-smallest": true,
    "text-fk-temp-light": true,
  },
  colorpicker__swatch: {
    relative: true,
    "fk-text-base": true,
    "w-[calc((100%/10)-0.5em)]": true,
    "max-w-[22px]": true,
    "m-[0.16em]": true,
    "cursor-pointer": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:rounded-fk-sm": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:pointer-events-none": true,
    "before:z-[2]": true,
    "group-data-[input-style=square]/container:before:rounded-none": true,
    "group-data-[input-style=pill]/container:before:rounded-full": true,
    "data-[active=true]:after:content-['']": true,
    "data-[active=true]:after:block": true,
    "data-[active=true]:after:absolute": true,
    "data-[active=true]:after:w-1.5": true,
    "data-[active=true]:after:h-1.5": true,
    "data-[active=true]:after:top-1/2": true,
    "data-[active=true]:after:left-1/2": true,
    "data-[active=true]:after:pointer-events-none": true,
    "data-[active=true]:after:rounded-full": true,
    "data-[active=true]:after:-translate-x-1/2": true,
    "data-[active=true]:after:-translate-y-1/2": true,
    "data-[active=true]:after:bg-white": true,
    "data-[active=true]:after:z-[2]": true,
    "data-[active=true]:after:ring-1": true,
    "data-[active=true]:after:ring-[rgba(0,0,0,0.33)]": true,
    "[&>canvas]:block": true,
    "[&>canvas]:w-full": true,
    "[&>canvas]:aspect-[1/1]": true,
    "[&>canvas]:rounded-fk-sm": true,
    "[&>canvas:focus-visible]:outline-none": true,
    "[&>canvas:focus-visible]:ring-2": true,
    "[&>canvas:focus-visible]:ring-fk-accent": true,
    "[&>canvas:focus-visible]:ring-offset-2": true,
    "[&>canvas:focus-visible]:ring-offset-white": true,
    "group-data-[input-style=square]/container:[&>canvas]:rounded-none": true,
    "group-data-[input-style=pill]/container:[&>canvas]:rounded-full": true,
  },
  datepicker__inner: {
    relative: true,
  },
  datepicker__removeSelection: {
    "self-stretch": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "ml-fk-spacing-sm": true,
    "mr-fk-spacing": true,
    "text-fk-temp": true,
    "hover:text-red-400": true,
    "z-10": true,
  },
  datepicker__clearIcon: {
    "[&>svg]:w-[0.75em]": true,
  },
  datepicker__panelWrapper: {
    "group/panel": true,
    absolute: true,
    "z-50": true,
    "min-w-[20em]": true,
    "top-[calc(100%_+_0.5em)]": true,
    "left-0": true,
    "shadow-[0_0_1.25em_rgba(0,0,0,.25)]": true,
    "rounded-fk-sm": true,
    "p-fk-spacing-xl": true,
    "bg-white": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:w-full":
      true,
  },
  datepicker__panelHeader: {
    grid: true,
    "grid-cols-[2.5em_1fr_2.5em]": true,
    "justify-center": true,
    "items-center": true,
    "border-b-2": true,
    "border-fk-temp-lighter": true,
    "mb-fk-spacing": true,
    "pb-fk-spacing-lg": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:grid-cols-[2.5em_1fr_2.5em_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=time]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:group-data-[panel=month]/panel:grid-cols-[2.5em_1fr_2.5em]":
      true,
  },
  datepicker__panelClose: {
    "aspect-[1/1]": true,
    border: true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-fk-temp": true,
    "[&_svg]:w-[1.25em]": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=time]/panel:col-start-3":
      true,
    "[@media(max-width:431px)_and_(hover:none)]:group-data-[panel=month]/panel:col-start-3":
      true,
  },
  datepicker__panel: {
    flex: true,
    "justify-center": true,
  },
  datepicker__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "placeholder:text-fk-temp-light": true,
  },
  datepicker__monthsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true,
  },
  datepicker__timeHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true,
  },
  datepicker__months: {
    grid: true,
    "grid-cols-3": true,
    "w-full": true,
  },
  datepicker__month: {
    "m-fk-spacing-sm": true,
    "p-fk-spacing-sm": true,
    "text-center": true,
    "text-fk-temp": true,
    "rounded-fk": true,
    "bg-fk-temp-form-bg": true,
    "cursor-pointer": true,
    "aria-selected:!bg-fk-accent": true,
    "aria-selected:!text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-fk-accent": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "focus:text-fk-temp": true,
    "data-[is-extra=true]:opacity-25": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__yearsHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-fk-temp": true,
    "col-start-2": true,
    "col-end-2": true,
  },
  datepicker__years: {
    grid: true,
    "grid-cols-5": true,
    "w-full": true,
  },
  datepicker__year: {
    "fk-text-base": true,
    "text-center": true,
    "text-fk-temp": true,
    "items-center": true,
    "m-fk-spacing-sm": true,
    "p-fk-spacing-sm": true,
    "rounded-fk": true,
    "bg-fk-temp-form-bg": true,
    "cursor-pointer": true,
    "aria-selected:!bg-fk-accent": true,
    "aria-selected:!text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-fk-accent": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "data-[is-extra=true]:opacity-25": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__weekDays: {
    grid: true,
    "grid-cols-7": true,
  },
  datepicker__weekDay: {
    "w-[2.25em]": true,
    "text-fk-temp": true,
    "m-fk-spacing-sm": true,
    "rounded-fk": true,
    "font-medium": true,
    lowercase: true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__week: {
    grid: true,
    "grid-cols-7": true,
    "group-data-[disabled=true]:opacity-50": true,
    "group-data-[disabled=true]:cursor-default": true,
    "group-data-[disabled=true]:pointer-events-none": true,
  },
  datepicker__dayCell: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "text-center": true,
    "text-fk-temp": true,
    "w-[2.25em]": true,
    "h-[2.25em]": true,
    "m-1": true,
    "p-fk-spacing": true,
    "rounded-fk": true,
    "bg-fk-temp-form-bg": true,
    "cursor-pointer": true,
    "aria-selected:bg-fk-accent": true,
    "aria-selected:text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-fk-accent": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "data-[is-extra=true]:opacity-25": true,
    "data-[disabled=true]:opacity-50": true,
    "data-[disabled=true]:cursor-default": true,
    "data-[disabled=true]:pointer-events-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__timeInput: {
    "w-full": true,
    "border-2": true,
    "[color-scheme:light]": true,
    "text-fk-temp": true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    "p-fk-spacing-sm": true,
    "my-fk-spacing-lg": true,
    "focus-visible:outline-fk-accent": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__daysHeader: {
    flex: true,
    "items-center": true,
    "justify-center": true,
  },
  datepicker__prev: {
    "mr-auto": true,
    "px-fk-spacing-lg": true,
    "py-fk-spacing-xs": true,
    "hover:bg-fk-temp-form-bg": true,
    "cursor-pointer": true,
    "rounded-fk": true,
    "col-start-1": true,
    "col-end-1": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__prevLabel: {
    hidden: true,
  },
  datepicker__prevIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-fk-temp": true,
    "[&>svg]:w-full": true,
  },
  datepicker__dayButton: {
    "appearance-none": true,
    "text-fk-temp": true,
    "cursor-pointer": true,
    "px-fk-spacing-lg": true,
    "py-fk-spacing-xs": true,
    "border-2": true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    "mx-1": true,
    "hover:border-fk-accent": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__monthButton: {
    "appearance-none": true,
    "text-fk-temp": true,
    "cursor-pointer": true,
    "px-fk-spacing-lg": true,
    "py-fk-spacing-xs": true,
    "border-2": true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    "mx-1": true,
    "hover:border-fk-accent": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__yearButton: {
    "appearance-none": true,
    "text-fk-temp": true,
    "cursor-pointer": true,
    "px-fk-spacing-lg": true,
    "py-fk-spacing-xs": true,
    "border-2": true,
    "border-fk-temp-lighter": true,
    "rounded-fk": true,
    "mx-1": true,
    "hover:border-fk-accent": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__next: {
    "ml-auto": true,
    "px-fk-spacing-lg": true,
    "py-fk-spacing-xs": true,
    "rounded-fk": true,
    "hover:bg-fk-temp-form-bg": true,
    "cursor-pointer": true,
    "hover:rounded": true,
    "col-start-3": true,
    "col-end-3": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  datepicker__nextLabel: {
    hidden: true,
  },
  datepicker__nextIcon: {
    flex: true,
    "w-[0.75em]": true,
    "select-none": true,
    "text-fk-temp": true,
    "[&>svg]:w-full": true,
  },
  datepicker__openButton: {
    "appearance-none": true,
    "border-0": true,
    "bg-transparent": true,
    flex: true,
    "p-0": true,
    "self-stretch": true,
    "cursor-pointer": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "focus-visible:ring-fk-accent": true,
    "focus-visible:ring-offset-2": true,
    "focus-visible:rounded-fk-sm": true,
    "group-data-[input-style=square]/container:focus-visible:rounded-none":
      true,
    "group-data-[input-style=pill]/container:focus-visible:rounded-full": true,
  },
  datepicker__calendarIcon: {
    "text-fk-temp": true,
    "focus-visible:text-fk-accent": true,
    flex: true,
    "w-[1em]": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true,
  },
  dropdown__placeholder: {
    "text-fk-temp-light": true,
    grow: true,
  },
  dropdown__selector: {
    flex: true,
    grow: true,
    "justify-between": true,
    "w-full": true,
    "py-fk-spacing": true,
    "pl-fk-spacing-lg": true,
    "pr-0": true,
    "fk-text-base": true,
    "text-fk-temp": true,
    "text-left": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "group-data-[prefix-icon]:!pl-0": true,
    "group-data-[suffix-icon]:!pr-0": true,
    "data-[placeholder]:text-fk-temp-light": true,
    "selection:bg-fk-accent-lightest": true,
  },
  dropdown__selectIcon: {
    "shrink-0": true,
  },
  dropdown__selectionsWrapper: {
    "w-[85%]": true,
    "overflow-hidden": true,
  },
  dropdown__selection: {
    "[&>*]:ml-0": true,
  },
  dropdown__selections: {
    "inline-flex": true,
    "items-center": true,
  },
  dropdown__selectionsItem: {
    "whitespace-nowrap": true,
    "mr-1": true,
  },
  dropdown__tagWrapper: {
    "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true,
  },
  dropdown__truncationCount: {
    flex: true,
    "items-center": true,
    "justify-center": true,
    "h-[1.5em]": true,
    "rounded-fk": true,
    "bg-fk-temp-lighter": true,
    "text-white": true,
    "whitespace-nowrap": true,
    "fk-text-smallest": true,
    "tracking-tighter": true,
    "leading-0": true,
    "py-fk-spacing-tiny": true,
    "px-fk-spacing-sm": true,
    "shrink-0": true,
    "my-auto": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  mask__inner: {
    relative: true,
  },
  mask__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "group-data-[has-overlay]:!caret-fk-temp-dark": true,
  },
  rating__inner: {
    "text-fk-temp-lighter": true,
  },
  rating__itemsWrapper: {
    relative: true,
    "inline-flex": true,
    "focus:border-fk-accent": true,
  },
  rating__onItemRow: {
    "h-full": true,
    "w-full": true,
  },
  rating__offItemRow: {
    "h-full": true,
    "w-full": true,
  },
  rating__onItemWrapper: {
    "[&>*]:w-full": true,
    "[&>*]:h-full": true,
    "w-full": true,
    "h-full": true,
    "text-yellow-400": true,
  },
  rating__offItemWrapper: {
    "text-fk-temp-lighter(0,300,500)": true,
    "w-full": true,
    "h-full": true,
    "[&>*]:w-full": true,
    "[&>*]:h-full": true,
  },
  rating__ratingItem: {
    relative: true,
    "focus-within:outline": true,
    "focus-within:outline-fk-accent": true,
    "w-[1.5em]": true,
    "h-[1.5em]": true,
  },
  rating__itemLabelInner: {
    "h-px": true,
    "w-px": true,
    "overflow-hidden": true,
    absolute: true,
    "white-space-nowrap": true,
  },
  rating__itemLabel: {
    absolute: true,
    "h-full": true,
  },
  rating__ratingIcon: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    flex: true,
  },
  rating__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "outline-none": true,
  },
  rating__messages: {
    "mt-fk-spacing-sm": true,
  },
  repeater__outer: {
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "min-w-0": true,
    grow: true,
    "group/repeater": true,
    "max-w-full": true,
  },
  repeater__fieldset: {
    "min-w-0": true,
  },
  repeater__legend: {
    "fk-text-small": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
    "mb-fk-spacing": true,
  },
  repeater__content: {
    "min-w-0": true,
    grow: true,
    "p-fk-spacing-2xl": true,
    flex: true,
    "flex-col": true,
    "align-center": true,
    "[&>div[data-type]]:max-w-none": true,
    "[&>div[data-type]:last-child]:mb-0": true,
  },
  repeater__addButton: {
    "!mb-0": true,
    "group-data-[disabled]/repeater:pointer-events-none": true,
    "group-data-[disabled]/repeater:opacity-50": true,
    "group-data-[disabled]/repeater:grayscale": true,
  },
  repeater__controlLabel: {
    absolute: true,
    "opacity-0": true,
    "pointer-events-none": true,
    "text-[0px]": true,
  },
  repeater__controls: {
    flex: true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "p-fk-spacing": true,
    "[&>li]:aspect-[1/1]": true,
    "rounded-fk-sm": true,
    "rounded-tl-none": true,
    "rounded-bl-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  repeater__downControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-fk-spacing-sm": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-fk-temp-light": true,
    "hover:text-fk-accent": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-fk-temp-light": true,
  },
  repeater__upControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-fk-spacing-sm": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-fk-temp-light": true,
    "hover:text-fk-accent": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-fk-temp-light": true,
  },
  repeater__removeControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-fk-spacing-sm": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-fk-temp-light": true,
    "hover:text-fk-accent": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-fk-temp-light": true,
  },
  repeater__insertControl: {
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "my-fk-spacing-sm": true,
    "mx-auto": true,
    flex: true,
    "items-center": true,
    "appearance-none": true,
    "justify-center": true,
    "aspect-[1/1]": true,
    "text-fk-temp-light": true,
    "hover:text-fk-accent": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "disabled:!text-fk-temp-light": true,
  },
  repeater__help: {
    "fk-text-smaller": true,
    "text-fk-temp-light": true,
    "mb-fk-spacing": true,
    "-mt-1": true,
  },
  repeater__item: {
    flex: true,
    relative: true,
    "w-full": true,
    "mb-fk-spacing": true,
    "bg-white/20": true,
    border: true,
    "border-fk-temp-lighter": true,
    "rounded-fk-sm": true,
    "shadow-sm": true,
    "[&.formkit-dropZone]:opacity-30": true,
    "[&.formkit-dropZone]:blur-[2px]": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=square]/container:shadow-none": true,
    "group-data-[input-style=pill]/container:rounded-2xl": true,
    "group-data-[input-style=underline]/container:shadow-none": true,
  },
  repeater__dragHandleWrapper: {
    relative: true,
    "w-8": true,
    "rounded-fk-sm": true,
    "rounded-tr-none": true,
    "rounded-br-none": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  repeater__dragHandle: {
    "w-full": true,
    "h-full": true,
    flex: true,
    absolute: true,
    "top-0": true,
    "left-0": true,
    "cursor-grab": true,
    "active:cursor-grabbing": true,
  },
  repeater__dragHandleIcon: {
    "w-2": true,
    "m-auto": true,
    "text-fk-temp-lighter": true,
    "[&>svg>path]:fill-current": true,
  },
  repeater__moveDownIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true,
  },
  repeater__moveUpIcon: {
    block: true,
    "w-[0.75em]": true,
    "aspect-[1/1]": true,
  },
  repeater__removeIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true,
  },
  repeater__addIcon: {
    block: true,
    "w-[1.25em]": true,
    "aspect-[1/1]": true,
  },
  slider__outer: {
    group: true,
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "max-w-none": true,
    "min-w-0": true,
    grow: true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
  },
  slider__help: {
    "fk-text-smaller": true,
    "text-fk-temp-light": true,
    "-mt-0.5": true,
    "mb-fk-spacing-sm": true,
  },
  slider__sliderInner: {
    flex: true,
    "items-center": true,
    "[&>[data-type=number]]:mb-0": true,
    "[&>[data-type=number]]:fk-spacing-lg": true,
    "[&>[data-type=number]]:shrink": true,
    "[&>[data-type=number]]:grow-0": true,
    "[&>[data-type=number]]:ml-fk-spacing": true,
    "[&[data-has-mark-labels=true]_[id^=track]]:fk-spacing-2xl": true,
  },
  slider__track: {
    grow: true,
    relative: true,
    "z-20": true,
    "py-fk-spacing-lg": true,
    "select-none": true,
  },
  slider__trackWrapper: {
    "px-[2px]": true,
    "rounded-full": true,
    "bg-fk-temp-lightest": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  slider__trackInner: {
    "h-fk-spacing-sm": true,
    "mx-0.5": true,
    relative: true,
  },
  slider__prefixIcon: {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-temp": true,
  },
  slider__suffixIcon: {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "text-fk-temp": true,
  },
  slider__fill: {
    "h-full": true,
    "rounded-full": true,
    absolute: true,
    "top-0": true,
    "-mx-1": true,
    "bg-fk-accent": true,
    "group-data-[disabled]:bg-fk-temp": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  slider__marks: {
    absolute: true,
    "pointer-events-none": true,
    "inset-0": true,
  },
  slider__mark: {
    absolute: true,
    "top-1/2": true,
    "w-[3px]": true,
    "h-[3px]": true,
    "rounded-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "bg-fk-temp-lighter": true,
    "data-[active=true]:bg-white": true,
  },
  slider__markLabel: {
    absolute: true,
    "top-[calc(100%+0.5em)]": true,
    "left-1/2": true,
    "text-fk-temp-light": true,
    "fk-text-smallest": true,
    "-translate-x-1/2": true,
  },
  slider__handles: {
    "m-0": true,
    "p-0": true,
    "list-none": true,
  },
  slider__handle: {
    group: true,
    "select-none": true,
    "w-4": true,
    "h-4": true,
    "rounded-full": true,
    "bg-white": true,
    absolute: true,
    "top-1/2": true,
    "left-0": true,
    "z-30": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.5)]":
      true,
    "focus-visible:outline-0": true,
    "focus-visible:ring-2": true,
    "ring-fk-accent": true,
    "data-[is-target=true]:z-20": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-full": true,
  },
  slider__tooltip: {
    absolute: true,
    "bottom-full": true,
    "left-1/2": true,
    "-translate-x-1/2": true,
    "-translate-y-[4px]": true,
    "bg-fk-accent": true,
    "text-white": true,
    "p-fk-spacing-sm": true,
    "text-xs": true,
    "leading-none": true,
    "whitespace-nowrap": true,
    "rounded-fk-sm": true,
    "opacity-0": true,
    "pointer-events-none": true,
    "transition-opacity": true,
    'after:content-[""]': true,
    "after:absolute": true,
    "after:top-full": true,
    "after:left-1/2": true,
    "after:-translate-x-1/2": true,
    "after:-translate-y-[1px]": true,
    "after:border-4": true,
    "after:border-transparent": true,
    "after:border-t-fk-accent": true,
    "group-hover:opacity-100": true,
    "group-focus-visible:opacity-100": true,
    "group-data-[show-tooltip=true]:opacity-100": true,
    "group-data-[input-style=square]/container:rounded-none": true,
    "group-data-[input-style=pill]/container:rounded-lg": true,
  },
  slider__linkedValues: {
    flex: true,
    "items-start": true,
    "justify-between": true,
  },
  slider__minValue: {
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
    "[&>div>div]:relative": true,
    '[&>div>div::after]:content-[""]': true,
    "[&>div>div::after]:absolute": true,
    "[&>div>div::after]:top-1/2": true,
    "[&>div>div::after]:left-[105.5%]": true,
    "[&>div>div::after]:w-[12%]": true,
    "[&>div>div::after]:h-[1px]": true,
    "[&>div>div::after]:bg-fk-temp-lightest": true,
  },
  slider__maxValue: {
    grow: true,
    "!max-w-[45%]": true,
    "mb-0": true,
    relative: true,
  },
  slider__chart: {
    relative: true,
    "z-20": true,
    "mb-2": true,
    flex: true,
    "justify-between": true,
    "items-center": true,
    "w-full": true,
    "aspect-[3/1]": true,
  },
  slider__chartBar: {
    absolute: true,
    "bottom-0": true,
    "h-full": true,
    "bg-fk-temp-lighter": true,
    "data-[active=false]:bg-fk-temp-lighter": true,
  },
  taglist__inner: {
    "py-fk-spacing": true,
    "pr-0": true,
    "pl-0": true,
  },
  taglist__tags: {
    "pl-fk-spacing-lg": true,
  },
  taglist__tagWrapper: {
    "[&.formkit-dropZone_.formkit-tag]:opacity-25": true,
    "[&.formkit-touchDropZone_.formkit-tag]:opacity-25": true,
  },
  taglist__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "!p-0": true,
    "!w-[0%]": true,
    "min-w-[1em]": true,
    "inline-block": true,
    "first:mt-0": true,
  },
  taglist__listboxButton: {
    "ml-auto": true,
    "shrink-0": true,
  },
  toggle__outer: {
    group: true,
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "min-w-0": true,
    grow: true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "max-w-none": true,
  },
  toggle__altLabel: {
    block: true,
    "w-full": true,
    "mb-fk-spacing-sm": true,
    "font-bold": true,
    "fk-text-smaller": true,
    "text-fk-temp": true,
  },
  toggle__inner: {
    peer: true,
    "inline-block": true,
    "mr-fk-spacing": true,
  },
  toggle__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    peer: true,
    absolute: true,
    "opacity-0": true,
    "w-0": true,
    "h-0": true,
  },
  toggle__label: {
    "fk-text-small": true,
    "mb-fk-spacing-sm": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
    "peer-first:font-normal": true,
    "peer-first:mb-0": true,
  },
  toggle__innerLabel: {
    absolute: true,
    "text-fk-temp-lightest": true,
    "text-[10px]": true,
    "font-bold": true,
    "select-none": true,
    "left-full": true,
    "top-1/2": true,
    "-translate-x-full": true,
    "-translate-y-1/2": true,
    "px-1": true,
  },
  toggle__thumb: {
    relative: true,
    "p-0.5": true,
    "left-0": true,
    "aspect-[1/1]": true,
    "rounded-full": true,
    "transition-all": true,
    "w-[1.25em]": true,
    "bg-white": true,
    "text-fk-temp": true,
    "shadow-base": true,
    "group-data-[input-style=square]/container:rounded-sm": true,
  },
  toggle__track: {
    "p-0.5": true,
    "min-w-[3em]": true,
    relative: true,
    "cursor-pointer": true,
    "select-none": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-fk-temp-lighter": true,
    "peer-checked:bg-fk-accent": true,
    "peer-checked:[&>div:last-child]:left-full": true,
    "peer-checked:[&>div:last-child]:-translate-x-full": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true,
    "shadow-none": true,
    "peer-focus-visible:ring-2": true,
    "peer-focus-visible:ring-fk-accent": true,
    "peer-focus-visible:ring-offset-2": true,
    "group-data-[input-style=square]/container:rounded-sm": true,
  },
  toggle__valueLabel: {
    "font-bold": true,
    "fk-text-smaller": true,
    "text-fk-temp": true,
  },
  toggle__wrapper: {
    flex: true,
    "flex-wrap": true,
    "items-center": true,
    "mb-fk-spacing-sm": true,
  },
  togglebuttons__wrapper: {
    "mb-fk-spacing-sm": true,
  },
  togglebuttons__options: {
    "group/options": true,
    "inline-flex": true,
    "data-[vertical=true]:flex-col": true,
  },
  togglebuttons__option: {
    "group/option": true,
  },
  togglebuttons__input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
    "!px-fx-spacing-xl": true,
    "!mb-0": true,
    relative: true,
    "focus:z-10": true,
    "group-data-[vertical=true]/options:w-full": true,
    "justify-center": true,
    "bg-fk-accent-lightest": true,
    "disabled:opacity-50": true,
    "disabled:cursor-not-allowed": true,
    "group-data-[disabled]:disabled:opacity-100": true,
    "aria-[pressed=true]:bg-fk-accent": true,
    "aria-[pressed=true]:text-white": true,
    "group-[]/option:rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:rounded-fk": true,
    "group-data-[vertical=true]/options:group-first/option:rounded-fk": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none":
      true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-br-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:rounded-fk": true,
    "group-data-[vertical=true]/options:group-last/option:rounded-fk": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none":
      true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none":
      true,
    "group-data-[input-style=square]/container:!rounded-none": true,
    "group-data-[input-style=pill]/container:group-data-[vertical=false]/options:group-first/option:rounded-full":
      true,
    "group-data-[input-style=pill]/container:group-data-[vertical=true]/options:group-first/option:rounded-3xl":
      true,
    "group-data-[input-style=pill]/container:group-data-[vertical=false]/options:group-last/option:rounded-full":
      true,
    "group-data-[input-style=pill]/container:group-data-[vertical=true]/options:group-last/option:rounded-3xl":
      true,
    "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
    "group-data-[vertical=false]/options:group-last/option:!border-r": true,
    "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-fk-accent":
      true,
    "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-fk-accent":
      true,
    "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-fk-accent":
      true,
    "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
    "group-data-[vertical=true]/options:group-last/option:!border-b": true,
    "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-fk-accent":
      true,
    "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-fk-accent":
      true,
    "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-fk-accent":
      true,
  },
};

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals: Record<string, Record<string, boolean>> = {
  outer: {
    group: true,
    "mb-fk-input-gap": true,
    "fk-text-base": true,
    "max-w-none": true,
    "min-w-0": true,
    grow: true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
  },
  label: {
    "fk-text-small": true,
    "mb-fk-spacing-sm": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
  },
  legend: {
    "fk-text-small": true,
    block: true,
    "text-fk-temp": true,
    "font-bold": true,
  },
  input: {
    "appearance-none": true,
    "[color-scheme:light]": true,
    "selection:bg-fk-accent-lighter": true,
    "selection:text-fk-temp": true,
    "group-data-[has-overlay]:selection:!text-transparent": true,
  },
  prefixIcon: {
    "-ml-fk-spacing-xs": true,
    "mr-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  suffixIcon: {
    "-mr-fk-spacing-xs": true,
    "ml-fk-spacing": true,
    "fk-text-base": true,
    flex: true,
    "items-center": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  loaderIcon: {
    "ml-fk-spacing": true,
    "fk-text-base": true,
    "animate-spin": true,
    flex: true,
    "items-center": true,
    "my-auto": true,
    "text-fk-temp-light": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
  },
  loadMoreInner: {
    "fk-text-small": true,
    "p-fk-spacing": true,
    "[&>span]:mr-fk-spacing": true,
    flex: true,
    "text-fk-temp-light": true,
    "items-center": true,
    "justify-center": true,
  },
  help: {
    "fk-text-smaller": true,
    "text-fk-temp-light": true,
  },
  message: {
    "px-fk-spacing": true,
    "py-fk-spacing-xs": true,
    "my-fk-spacing-xs": true,
    "mr-fk-spacing-xs": true,
    "fk-text-smaller": true,
    "inline-flex": true,
    "bg-red-600": true,
    "text-red-50": true,
    "rounded-lg": true,
    "leading-tight": true,
  },
  overlay: {
    "text-fk-temp": true,
  },
  overlayPlaceholder: {
    "text-fk-temp-light": true,
  },
  overlayLiteral: {
    "text-fk-temp": true,
  },
  overlayChar: {
    "text-fk-temp": true,
  },
  overlayEnum: {
    "text-fk-temp": true,
  },
};
